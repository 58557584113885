.services {
  text-align: center;
  margin: 6rem 0rem;
  padding: 3rem 0;
  width: 100%;
  position: relative;
  overflow: hidden;

  .title {
    font-size: 48px;
    font-weight: bold;
    margin: 1rem 0;
  }

  .line {
    width: 40%; 
    height: 4px;
    background-color: black;
    margin-bottom: 4vmax;
  }
  .service_box_image {
    transform: rotate(90deg);
    position: absolute;
    left: -40%;
    top:-35%;
    z-index: -1; 
  }
}

.servic_card {
    padding: 1rem;
    min-height: 21rem;
    max-height: 100%;

    img {
        height: 184px;
        width: 184px;
    }

    h1{
        font-size: 28px;
        font-weight: bold;
        margin: 1rem 0;
    }
    p{
        font-size: 16px;
        font-weight: normal;
    }
}

@media screen and (max-width: 488px) {
  .services {
    text-align: center;
    margin: 4rem 0rem;
  
    .title {
      font-size: 40px;
      font-weight: bold;
      margin: 1rem 0;
    }
  
    .line {
      width: 100%;  
      margin-bottom:2rem;
    }
    .service_box_image {
      transform: rotate(70deg);
      position: absolute;
      left: -145%;
      top: -15%;
      z-index: -1;  
    }
  } 
}