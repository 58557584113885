.header {
    width: 100%; 
    min-height: 650px; 
    background-image: url(./../../assets/bg.png);
    background-size: cover;
    background-position-x: center; 
    background-position-y: 80%; 
    background-repeat: no-repeat;   

    .header_social {
        margin-top: 4rem;
        display: none; 
        // display: flex;
        align-items: center;
        justify-content: center;
    }
}

.header_text {
    margin-top: 4vmax;

    h2 {
        color: white;
        font-weight: bold;
        font-size: 48px;
    }
    p {
        color: white;
        font-weight: 400;
        margin-top: 1rem;
    }
}

@media screen and (max-width: 488px) {
 
    .header_text {
        margin-top: 1rem;
    
        h2 {
            text-align: center;
            font-size: 38px;
        }
        p {
            text-align: center;
            margin-top: 1rem;
        }
    }

    .btn_group {
        justify-content: center;
    }
    .header_social {
        display: flex !important;
    }
}

@media screen and (max-width: 380px) {
    .header  {
        min-height: 100vh !important;
        padding-bottom: 4rem;
    }
}