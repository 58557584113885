* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body{
    font-family:  sans-serif;
}

ul {
  list-style: none;
}


button {
  border: none;
  background-color: white;
  cursor: pointer;
}