
.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 4vmax;

}

.main_logo {
    height: 67px;
}


@media screen and (max-width: 488px) {
    .navbar {
        justify-content: center;
        padding: 1rem 0;
    }
    .nav_social {
        display: none;
    }
}