.contact_us {
  background: #000;
  color: white;
  padding: 4rem 0;
}
.contact_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  h1 {
    font-size: 48px;
    margin-bottom: 1rem;
  }
  p {
    line-height: 24px;
    font-size: 16px;
  }
}

.contact_form {
  padding: 1rem;
  div {
    margin-bottom: 1rem;
    input,
    textarea {
      width: 90%;
      outline: none;
      padding: 0.5rem 1rem;
    }
    p {
      margin-bottom: 0.5rem;
    }
  }
  button {
    padding: 10px 20px;
  }
}


@media screen and (max-width: 488px){
  .contact_us { 
    padding: 2rem 0;
  }
  .contact_text {
  
    h1 {
      font-size: 40px;
      margin-bottom: 1rem;
      text-align: center;
    }
    p {
      line-height: 22px;
      font-size: 16px;
      text-align: center;
    }
  }

  .contact_form {
    display: flex;
    flex-direction: column;
    align-items: center;
    div {
      width: 100%;
    }
  }
}