.d_f {
  display: flex;
}

.a_i_c {
  align-items: center;
}

.j_c_b {
  justify-content: space-between;
}
.j_c_c{
    justify-content: center;;
}

// btn
.btn_group {
  display: flex;
  gap: 1.5rem;
  margin-top: 2rem;
}

.primary_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 18px;
  gap: 10px;
  background: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.black_btn {
  padding: 18px 44px; 
  background: #000000;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  text-transform: capitalize;
  color: #ffffff;
}

.w_100 {
  max-width: 100%;
}


// 
@media screen and (max-width: 488px) {
  .black_btn {
    padding: 16px 32px; 
    background: #000000;
    font-style: normal;
    font-weight: 400;
    font-size: 16px; 
    /* identical to box height */
    text-transform: capitalize;
    color: #ffffff;
  }
}