.footer {
  background: #000;
  color: white;
  max-width: 100%;
  height: 100%;
}
.footerImg {
  height: 550px;
  width: 100%;
  object-fit: cover;
}

.footer_right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 2rem;

  > img {
    margin-top: 1rem;
  }
  button {
    padding: 10px 16px;
  }
  .paymentMehtod {
    background-image: url(./../../assets/paymentbg.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 90%;

    img {
      width: 100%;
    }
  }
  p {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

  .copy_p {
    margin-top: 4rem;
  }
}

@media screen and (max-width: 488px) {
  .footerImg {
    display: none;
  }

  .footer_right {
    border-top: 1px solid #fff;
    // margin-top:  2rem;
    button {
      padding: 10px 16px;
    }
    > img {
      margin-top: 2rem;
    }
    .paymentMehtod {
      background-image: url(./../../assets/paymentbg.png);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 90%;

      img {
        width: 100%; 
      }
    }
    p {
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }

    .copy_p {
      margin-top: 4rem;
      font-size: 14px;
    }
  }
}
