.did_you_know_text {
  height: 100%;
  padding-left: 8vmax;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    font-size: 48px;
  }
  .line {
    height: 4px;
    width: 60%;
    background-color: black;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 1rem;
    color: #000000;
  }
}

.did_you_know_img {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

.laptop_image { 
  display: none;
}

@media screen and (max-width: 488px) {
  .laptop_image {
    display: block;
    img {
      width: 100%;
    }
  }
  .did_you_know_img {
    display: none;
  }

  .did_you_know_text { 
    padding: 1rem; 
  
    h1 {
      font-size: 40px;
      text-align: center;
    }
    .line {
      height: 4px; 
      width: 70%; 
    }
    .line_p_div {
      display: flex;
      align-items: center;
      justify-content: center
    }
    p {
      text-align: center;
    }

    span {
      text-align: center;
    }
  }
}
