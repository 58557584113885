.why_choose_us {
  width: 100%;
  background-image: url(./../../assets/choose-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  padding: 4rem 0;
  display: flex;
  justify-content: center;
  //   object-fit: cover;

  h1 {
    font-size: 48px;
    font-weight: bold;
    color: white;
    border-bottom: 3px solid #4d4d4d;
    padding: 1rem 2rem;
    margin-bottom: 2rem;
  }
}

.choose_card {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 1rem 0;

  button {
    padding: 14px 28px;
    margin-top: 0.5rem;
    background-color: transparent;
    color: white;
    border: 1px solid #ffffff;
  }
}

.offer_section {
  width: 100%;
  min-height: 80vh;
  display: flex;
  align-items: center;
  color: white;

  background-image: url(./../../assets/offer-bg.png);
  background-position-x: center;
  background-position-y: 75%;
  background-repeat: no-repeat;
  background-size: cover;

  .offered_card {
    h1 {
      font-size: 48px;
      font-weight: bold;
    }

    p {
      font-size: 20px;
      margin: 1.5rem 0;
    }
    button {
      padding: 14px 18px;
    }
  }
}

@media screen and (max-width: 488px) {
  .why_choose_us {
    background-position: center;
    //   object-fit: cover;
    h1 {
      font-size: 40px;
    }
  }
 
  .choose_card {
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 1rem 0;

    img {
      width: 100px;
      height: 100px;
    }
    button {
      padding: 14px 24px;
    }
  }
  .offer_section {
    min-height: 300px;
    background-size: 100% 100%;

    .offered_card {
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        font-size: 40px;
      }

      p {
        font-size: 16px;
        margin: 1rem 0;
        line-height: 22px;
        text-align: center;
      }
    }
  }
}
