.who_are_you_text {
  padding-top: 1rem;
  padding-right: 6vmax;
  h1 {
    font-size: 48px;
    font-weight: bold;
    padding-bottom: 0.5rem;
    border-bottom: 4px solid #000;
  }
  p {
    font-size: 16px;
    font-weight: normal;
    margin: 2rem 0;
    line-height: 24px;
  }
}

.who_are_you_img {
  position: relative;

  .box_image {
    width: 38vmax;
    height: 38vmax;
    position: absolute;
    top: -20%;
    left: 12%;
  }
}

@media screen and (max-width: 488px) {
  .who_are_you_text {
    padding: 1rem;
    h1 {
      font-size: 44px;
      border-bottom: 4px solid #000;
      text-align: center;
    }
    p {
      text-align: center;
    }
  }
  .contact_bt_div {
    text-align: center;
  }
  .who_are_you_img {
    margin-top: 3rem;
    position: relative;

    .box_image {
      width: 100%;
      height: 130%;
      position: absolute;
      top: -14%;
      left: 0%;
    }
  }
}
